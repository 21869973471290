.header {
  padding: 0 40px;
  width: 100%;
  height: 100%;

  &__breadcrumb {
    font-size: 18px;
    font-weight: 600;
  }
}

.language {
  cursor: pointer;

  &__flag {
    width: 45px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__icon {
    margin-left: 23px;
  }
}
