.ranking {
  &__options {
    gap: 20px;

    &-media {
      border-radius: 8px;
      height: 520px;
      width: 100%;

      img,
      img:hover,
      img:focus,
      .ant-image-mask {
        object-fit: cover;
        border-radius: 4px;
      }
    }

    &-content {
      gap: 20px;

      &-item {
        gap: 30px;

        &-image {
          border-radius: 4px;
          img,
          img:hover,
          img:focus,
          .ant-image-mask {
            object-fit: cover;
            border-radius: 4px;
          }
        }

        &-option {
          gap: 20px;

          &-title {
            padding: 12px;
            background: #f4f4f2;
            border-radius: 8px;
            height: 48px;

            span {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
          }

          &-calc {
            gap: 24px;

            .vote {
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
            }

            .count {
              font-weight: 900;
              font-size: 20px;
              line-height: 24px;
            }

            .number {
              padding: 12px;
              font-weight: 900;
              font-size: 20px;
              line-height: 24px;
              background: #f4f4f2;
              border-radius: 8px;
              width: 48px;
              height: 48px;
            }

            button {
              height: 48px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
