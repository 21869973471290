@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

@import './base/variables';
@import './base/font';
@import './base/reset';
@import './base/ultilities';
@import './base/mixins';

@import './vendors/bootstrap';
@import './vendors/toastify';
@import './vendors/ant';
@import './vendors/quill';

@import './base/typography';
@import './base/base';

@import './components/button';
@import './components/filter';
@import './components/table';

@import './layouts/menu';
@import './layouts/header';
@import './layouts/footer';
@import './layouts/navigation';
@import './layouts/main';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: $default-font-size;
  color: $color-black-21;
  font-style: normal;
}
