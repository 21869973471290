@import '@/styles/base/variables';

.text {
  &--uppercase {
    text-transform: uppercase;
  }

  &--lowercase {
    text-transform: lowercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--error {
    color: $color-error;
    padding: 0 !important;
    margin: 6px 0 0 0 !important;
    margin-top: 6px;
    font-weight: 400;
    font-size: 12px !important;
    line-height: auto !important;
    text-align: start !important;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    justify-content: flex-start !important;
  }
}

input,
.ant-picker {
  &.--error {
    border: 1px solid $color-error !important;
  }
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-white {
  color: $color-white;
}

.text-black {
  color: $color-black;
}

.mh-100 {
  max-height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}
