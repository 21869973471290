$colorRed: #ff0000;
$color-red-e3: #e3242b;
$color-red-c4: #c41d7f;
$color-black: #000000;
$color-white: #ffffff;

$color-error: #d9534f;

$color-black-21: #212121;
$color-black-1c: #1c1c1c;

$color-blue-e5: #e5ecf6;
$color-blue-f6: #0197f6;
$color-blue-c7: #3279c7;
$color-blue-ff: #e3f5ff;

$color-gray-e4: #e4e4e7;
$color-gray-f7: #f7f9fb;
$color-gray-f8: #f8f8f8;
$color-gray-29: #111729;
$color-gray-33: rgba(33, 33, 33, 0.8);
$color-gray-f1: #f1f2f6;

$color-green-13: #03ac13;

$color-yellow-19: #ffd819;
$color-yellow-5a: #d6b85a;

$background: #f4f4f2;

$default-font-size: 15px;
