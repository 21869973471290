@import '@/styles/base/variables';

.ban {
  &__group {
    max-height: 100%;
    overflow-y: auto;

    &-item {
      padding: 12px 20px;
      margin-right: 28px;
      background: #ffffff;
      border: 1px solid rgba(85, 86, 90, 0.12);
      border-radius: 8px;
      margin-bottom: 16px;

      &__title {
        color: $color-gray-29;
        margin-right: 29px;
      }

      &__icon {
        font-size: 15px;
        font-weight: bolder;
        cursor: pointer;
      }
    }
  }
}
