.table {
  width: 100%;
  height: auto;

  &__heading {
    &-title {
      color: $color-black-1c;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }

  &__grid {
    &-pagination {
      text-align: right;
      margin-top: 30px;

      ul {
        font-size: 12px;
      }
    }
  }

  &__action {
    &--default {
      color: $color-blue-f6;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
