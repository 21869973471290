@import 'antd/dist/reset.css';

.ant-table-body {
  min-height: 400px;
  overflow-y: auto !important;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-collapse-header-text {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.ant-pagination-item,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: none !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  color: $color-blue-c7 !important;
  font-weight: bold;
  font-size: 14px;
}
