@import '@/styles/base/variables';

.package {
  .ant-card-head {
    background-color: $color-gray-f1 !important;
    font-weight: 600;
    text-align: center;
  }
}

.function {
  table {
    thead {
      background-color: $color-gray-f8;
    }

    tr > th {
      text-align: center;
      vertical-align: middle;
    }

    tbody {
      tr {
        height: 70px;
        vertical-align: middle;
      }
    }

    input {
      width: 65%;
      text-align: center;
    }
  }
}
