@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

.quill {
  background: #f4f4f2;
  border-radius: 4px;
  position: relative;
  padding: 12px 0 0 0;

  .ql-toolbar {
    background: #ffffff;
    box-shadow: 0px 0px 1.6px rgba(0, 0, 0, 0.11),
      0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;
    margin: 0 16px;
  }

  .ql-formats {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1800px) {
      justify-content: flex-start;
    }
  }

  .ql-editor {
    min-height: 200px;
    max-height: 500px;
  }

  .ql-container {
    border: none;
  }
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  padding: 0.5em 10px;
  color: #666;
  font-style: italic;
}

.ql-container.ql-snow {
  border: none !important;
}
