.logo {
  width: 142px;
  height: 64px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
