.poll-detail {
  background: #ffffff;
  border-radius: 8px;

  padding: 20px 20px 24px;
  gap: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

  &__header {
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.87);

      .title {
        flex: 0 70%;

        h1 {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;

          letter-spacing: 0.15px;
        }
      }

      .publish-time {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.15px;
      }
    }

    &-description {
      padding: 0;
      line-height: 1.6;
    }
  }

  &__type-poll {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 12px;
    gap: 8px;

    height: 40px;

    background: #f4f4f2;
    border-radius: 4px;

    &--text {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height, or 131% */

      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__other {
    span {
      height: 32px;
      font-size: 16px;
      font-weight: 500;
      color: #2b2b2b !important;
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  &__author {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    &-avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #bdbdbd;
      object-fit: contain;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }

    &-info {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      gap: 4px;

      color: rgba(0, 0, 0, 0.87);
      letter-spacing: -0.01em;

      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */
      }

      .other {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        /* 00. On Surface / Medium Emphasis */

        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__comment {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &-header {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      letter-spacing: 0.15px;

      color: rgba(0, 0, 0, 0.87);
    }
    &-input {
      padding: 12px 12px 48px;
      background: #f4f4f2;
      border-radius: 4px;
      height: 82px;
      width: 100%;

      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.48);
    }
  }
}
