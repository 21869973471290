@import '@/styles/base/variables';

.login {
  width: 50%;
  height: 100vh;

  &-container {
    width: 100%;
    height: 100vh;

    &__background {
      width: 50%;
      height: 100vh;
      background-image: url('./../../assets/images/png/background-login.png');
      background-position: center;
      background-color: #f4f4f2;
      background-repeat: no-repeat;
    }
  }

  &__description {
    margin-top: 25px;
    font-size: 20px;
    line-height: 23px;
  }

  &__form {
    width: 520px;
    height: auto;
    margin-top: 20px;

    &-input {
      box-sizing: border-box;
      padding: 16px;
      width: 100%;
      height: 48px;

      border: 1px solid $color-gray-e4;
      border-radius: 12px;
      margin-top: 8px;

      &:focus {
        border: 1px solid $color-gray-33;
      }
    }

    &-group-input {
      position: relative;

      img {
        position: absolute;
        top: 24px;
        right: 15px;
        cursor: pointer;
      }
    }

    &-forgot-password {
      margin-bottom: 16px;
      text-align: right;
      cursor: pointer;
      font-size: 15px;
      text-decoration: underline;
    }

    &-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      width: 100%;
      height: 48px;

      border-radius: 12px;
    }

    &-language {
      margin-top: 20px;

      center {
        justify-content: flex-end;
      }
    }
  }
}
