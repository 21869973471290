@import '@/styles/base/variables';

.ads {
  &__banner {
    &-vertical {
      width: 200px;
      height: 300px;
      border: 1px dashed rgba(0, 0, 0, 0.4);
      border-radius: 4px;
    }

    &-horizontal {
      width: 600px;
      height: 170px;
      border: 1px dashed rgba(0, 0, 0, 0.4);
      border-radius: 4px;
    }

    &-video {
      width: 350px;
      height: 300px;
      border: 1px dashed rgba(0, 0, 0, 0.4);
      border-radius: 4px;
    }

    .ant-image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.input {
  &_disable {
    cursor: auto !important;
    background-color: white !important;
    color: $color-black !important;
  }
}

.icon {
  &_download {
    margin-bottom: 3px;
    margin-left: 12px;
    font-size: 22px;
    color: $color-blue-f6;

    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
