@import '@/styles/base/variables';

.image {
  &__group {
    max-height: 100%;
    overflow-y: auto;
    padding: 7px;

    &-item {
      position: relative;
      margin-right: 25px;
      margin-top: 15px;
      &__image {
        width: 150px;
        height: 150px;
        border: 0.5px solid $color-gray-e4;
        border-radius: 8px;

        .ant-image {
          width: 100%;
          height: 100%;

          .ant-image-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__icon {
        z-index: 10;
        position: absolute;
        top: -10px;
        right: -7px;
        cursor: pointer;
      }
    }
  }
}
