/* CSS for the LoadingOverlay component */
.loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  transition: opacity 0.3s ease;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  &-text {
    margin-top: 1rem;
  }

  .spinner {
    width: 3rem;
    height: 3rem;
  }
}
