@import '@/styles/base/variables';

.dashboard {
  width: 100%;

  & > * {
    margin: 30px 0;
  }

  &_firstline {
    &_item {
      background-color: $color-blue-ff;
      padding: 30px;
      border-radius: 16px;

      &_title {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 12px;
      }

      &_value {
        font-family: Roboto;
        font-size: 26px;
        font-weight: 800;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
      }

      &_dif {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        width: auto;

        img {
          float: right;
          margin-left: 5px;
          margin-top: 3px;
        }
      }
    }
  }

  &_secondline {
    min-height: 200px;
  }

  &_thirdline {
    min-height: 250px;
  }

  &_chart {
    padding: auto;
    width: 100%;
    height: 450px;
    padding: 24px;
    border-radius: 16px;
    background-color: $color-gray-f7;

    &_short {
      height: 400px;
    }

    &_title {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 10px;
    }

    &_wallpaper {
      width: 100%;
    }
  }
}

.ant-col:nth-of-type(2n) .dashboard_firstline_item {
  background-color: $color-blue-e5;
}

.custom-tooltip {
  background-color: white;
  padding: 8px 15px 2px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.login_icon {
  font-size: 20px;
  margin-right: 5px;
}

.line {
  &-dot {
    height: 10px;
    width: 10px;
    margin-right: 6px;
    border-radius: 50%;
    display: inline-block;
  }

  &-square {
    height: 10px;
    width: 10px;
    margin-right: 6px;
    display: inline-block;
  }
}

.carousel_dot {
  margin-bottom: -24px;
}

.ant-carousel .slick-dots li button {
  background: $color-blue-c7;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  background: $color-blue-c7;
  opacity: 1;
}
