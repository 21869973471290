.comparison {
  gap: 20px;

  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 30px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-media {
        border-radius: 4px;
        height: 288px;
        width: 100%;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

        video,
        img,
        img:hover,
        img:focus,
        .ant-image-mask {
          object-fit: cover;
          border-radius: 4px;
        }
      }

      &-content {
        gap: 10px;

        &-option {
          cursor: pointer;
          height: 48px;
          padding: 12px;

          background: #1565c0;
          border-radius: 8px;
          color: rgba(255, 255, 255, 0.87);
          gap: 10px;

          &.is-first {
            background: #2e7d32;
          }
        }

        &-calc {
          gap: 10px;

          span {
            font-weight: 700;
          }
        }
      }
    }

    &-rate {
      height: 48px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.24);
    }
  }
}
