@import '@/styles/base/variables';

.category {
  width: 65%;
  height: auto;
  max-height: 100%;
  background-color: $color-gray-f7;
  border-radius: 16px;
  padding: 24px 28px 28px;

  &__heading {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 28px;
  }

  &__group {
    padding: 0 28px 0 28px;
    max-height: 100%;
    overflow-y: auto;

    &-item {
      padding: 18px 24px;

      border-bottom: 1px solid rgb(128, 128, 128, 0.2);

      input {
        font-size: 15px;
      }

      &__title {
        color: $color-gray-29;
      }
      &--parent {
        padding-left: 0;

        input {
          font-size: 17px;
          font-weight: 700;
        }
      }
    }
  }
}
