@import '@/styles/base/variables';

.forgot-password {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  height: 100%;
  align-items: stretch;
  min-height: 100vh;

  &__quit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;

    position: absolute;
    width: 72px;
    height: 72px;
    right: 72px;
    top: 52px;
    justify-content: center;
    cursor: pointer;

    background: #f4f4f2;
    border-radius: 48px;
    border: none;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border: 1px solid $color-gray-33;
      background-color: #ebebe9 !important;
    }
  }

  &__welcome {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    /* or 130% */

    display: flex;
    align-items: center;
    text-align: center;
  }

  &__background {
    min-height: 100vh;
    height: 100%;
    width: 960px;
    background-image: url('./../../assets/images/png/background-forgot.png');
    background-position: center;
    flex: 0 0 50%;
    background-color: #f4f4f2;
    background-repeat: no-repeat;

    &--secondary {
      background-image: url('./../../assets/images/png/background-forgot-2.png');
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 50%;
    justify-content: center;
    height: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 180px;
  }

  &__otp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
    width: 100%;

    input {
      padding: 10px 12px 14px;
      width: 48px;
      height: 48px;
    }
  }

  &__form {
    margin-top: 40px;
    display: flex;
    width: 512px;
    flex-direction: column;

    &-input {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 5px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height, or 131% */

      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: center;

      span {
        margin-left: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;

        a {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }

  &__not-user {
    margin-top: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    p {
      margin-top: 0;
    }

    a {
      text-decoration: none;
      color: #000000;
      font-weight: 700;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 32px;
    gap: 10px;
    border-radius: 8px;
    margin-top: 32px;
    height: 48px;
  }
}
