.menu {
  padding-top: 16px;
  height: 100%;

  &__logo {
    width: 100px;
    height: 50px;
    margin-left: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__list {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 50px;
    max-height: 80%;
    overflow-y: auto;

    &-item {
      color: rgba(33, 33, 33, 0.5);
      width: 90%;
      padding: 15px 0 15px 30px;
      cursor: pointer;

      &--active {
        color: $color-black-21;
        background: #f7f9fb;
        position: relative;
        font-weight: 500;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background: $color-black-21;
          border-radius: 4px 0px 0px 4px;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
      }
    }
  }

  &__list::-webkit-scrollbar {
    display: none !important;
  }

  &-collapse {
    font-size: 15px;
    color: rgba(33, 33, 33, 0.5);

    &__item {
      padding: 10px 0 10px 65px;
    }
  }

  &__account {
    margin-left: 30px;
    margin-bottom: 30px;
    cursor: pointer;

    &-avatar {
      width: 40px;
      height: 40px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-infors {
      div:first-child {
        font-size: 14px;
        font-weight: 600;
      }

      div:last-child {
        font-size: 12px;
      }
    }
  }
}
