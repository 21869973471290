$arrayColor: ();

@for $i from 1 through 20 {
  $arrayColor: append($arrayColor, hsl(calc($i * 360 / 30), 100%, 50%));
}

@each $color in $arrayColor {
  $index: index($arrayColor, $color);

  .color-#{$index} {
    button {
      border-color: #{$color};
      color: #{$color};

      &:hover,
      &:focus {
        background-color: #{$color};
        border-color: #{$color} !important;
        color: #fff !important;
      }
    }
  }
}
