.judgement {
  &__options {
    gap: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);

    &-media {
      border-radius: 4px;
      height: 288px;
      width: 100%;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

      video,
      img,
      img:hover,
      img:focus,
      .ant-image-mask {
        object-fit: cover;
        border-radius: 4px;
      }
    }

    &-content {
      gap: 20px;

      &-item {
        cursor: pointer;
        gap: 10px;

        &-option {
          gap: 10px;
          border-radius: 8px;
          padding: 12px;
          color: rgba(255, 255, 255, 0.87);
          background: #1565c0;

          &.is-first {
            background: #2e7d32;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
          }
        }

        &-calc {
          gap: 10px;

          span {
            font-weight: 700;
          }
        }
      }

      &-rate {
        height: 48px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.24);
      }
    }
  }
}
