@import '@/styles/base/variables';

.page-loader {
  background-color: $background;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: none;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
