.fw-600 {
  font-weight: 600;
}

.text-active {
  color: $color-green-13;

  &--border {
    border-color: $color-green-13;

    &:hover {
      color: $color-green-13 !important;
      border-color: $color-green-13 !important;
      opacity: 0.6;
    }
  }
}

.text-warning {
  color: $color-yellow-19;
}

.text-block {
  color: $color-red-e3;
}

.text-reject {
  color: $color-red-c4;
}
