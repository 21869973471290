.survey {
  &__options {
    gap: 20px;

    &-media {
      border-radius: 8px;
      height: 520px;
      width: 100%;

      img,
      img:hover,
      img:focus,
      .ant-image-mask {
        object-fit: cover;
        border-radius: 4px;
      }
    }

    &-content {
      gap: 20px;

      &-item {
        gap: 30px;

        &-image {
          border-radius: 4px;
          img,
          img:hover,
          img:focus,
          .ant-image-mask {
            object-fit: cover;
            border-radius: 4px;
          }
        }

        &-question {
          gap: 20px;

          &-title {
            padding: 12px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;

            color: rgba(0, 0, 0, 0.87);

            height: 48px;
          }

          &-option {
            &-title {
              padding: 12px;
              background: #f4f4f2;
              border-radius: 8px;
              height: 48px;
            }

            gap: 24px;

            font-weight: 600;
            font-size: 16px;
            line-height: 21px;

            color: rgba(0, 0, 0, 0.87);

            .number {
              padding: 12px;
              background: #f4f4f2;
              border-radius: 8px;
              width: 48px;
              height: 48px;
            }

            &-item {
              gap: 10px;

              .option {
                padding: 12px;
                background: #f4f4f2;
                border-radius: 8px;
                height: 48px;
              }
            }

            button {
              height: 48px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
