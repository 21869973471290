.filter {
  width: 100%;
  height: auto;
  padding: 24px 28px 28px;
  background: $color-gray-f7;
  border-radius: 16px;

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__sub-title {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.38);
    margin-top: 8px;
  }

  &__form {
    width: 100%;
    height: auto;
    margin-top: 28px;

    &-label {
      color: $color-black;
      margin-bottom: 7px;
    }

    .ant-input-number {
      width: 100%;
    }
  }
}
