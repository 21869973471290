@import '@/styles/base/variables';

.container {
  padding: 24px 28px 28px 28px;
  background: $color-blue-e5;
  font-size: 16px;
  border-radius: 16px;

  &_wheel {
    padding: 24px 28px 28px 28px;
    font-size: 16px;
    border-radius: 16px;
    background: $color-blue-ff;
  }
}

.gift {
  margin: 0 auto 15px;
}

.icon {
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  &_delete {
    color: $color-error;
  }

  &_confirm {
    color: $color-green-13;
  }
}
