.main {
  &-container {
    width: 100%;
    height: 100vh;
  }

  &-left {
    width: 16%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-right {
    width: 84%;
    height: 100%;

    &__header {
      width: 100%;
      height: 76px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__content {
      width: 100%;
      height: 100%;
      max-height: 100%;
      padding: 40px 40px 10px 40px;
      overflow-y: auto;
    }
  }
}
